import {
  AuthActionTypes as ActionTypes,
  AuthActionUnion as ActionUnion,
  SetUserAttributes,
} from '../../common/actions/auth.actions';
import {
  CustomerPK,
  User,
  Entitlement,
  RolePermission,
  CustomerSearchResult,
  ApplicationUserRole,
} from '@gfs/shared-models';
import { InventoryConstants } from '@gfs/constants';
import { Md5 } from 'ts-md5';
import * as _ from 'lodash';

export enum Authorization {
  RecipeProfitCalculator = 'RecipeProfitCalculator'
}

export type Authorizations = {
  [Authorization: string]: boolean
}

export const authorizations: string[] = Object
  .values(Authorization)
  .filter(val => isNaN(Number(val)))
  .map(val => String(val))
  ;

export interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  pk: CustomerPK | null;
  error: string | null;
  entitlements: Entitlement[] | null;
  customerName: string | null;
  rolePermissions: RolePermission[];
  authorizations: Authorizations;
  customerSearchResults: CustomerSearchResult[];
}

export const initialAuthState: AuthState = {
  isAuthenticated: false,
  user: null,
  pk: null,
  error: null,
  entitlements: [],
  customerName: null,
  rolePermissions: null,
  authorizations: {
    [Authorization.RecipeProfitCalculator]: false
  },
  customerSearchResults: null,
};

export function authReducer(
  state: AuthState = initialAuthState,
  action: ActionUnion
): AuthState {
  switch (action.type) {
    case ActionTypes.LogInSuccess: {
      return {
        ...state,
        user: {
          ...action.payload.user,
          entitlements: []
        },
        isAuthenticated: true,
      };
    }
    case ActionTypes.LogOutSuccess: {
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      };
    }
    case ActionTypes.LogOutError:
    case ActionTypes.LogInError: {
      return {
        ...state,
        error: action.error.message,
      };
    }
    case ActionTypes.SetCustomerPK: {

      action.payload.customerPK.hash = Md5.hashStr(JSON.stringify(action.payload.customerPK));
      const newState = {
        ...state,
        pk: action.payload.customerPK,
      };

      if (action.payload.customerName) {
        newState.customerName = action.payload.customerName
      }

      // Because Syncope is where entitlements are configured to (Okta) users,
      //    Employee users are not matched to any entitlements.
      // If an employee user dispatches a SetCustomerPK action,
      //    then an entitlement needs to be created that matches the selected customer location.
      const isEmployee = action.payload.userRole == ApplicationUserRole.Employee;
      if (isEmployee) {
        const entitlement = {
          customerPK: action.payload.customerPK,
          customerName: action.payload.customerName
        } as Entitlement;

        newState.user = {
          ...newState.user,
          entitlements: [entitlement]
        }
      }

      return newState;
    }

    case ActionTypes.SetAuthorization: {
      return {
        ...state,
        authorizations: {
          ...state.authorizations,
          [action.payload.authorization]: action.payload.value
        }
      };
    }
    case ActionTypes.SetUserAttributes: {
      return handleSetUserAttributes(state, action);
    }
    case ActionTypes.SetCustomerLocations: {
      return {
        ...state,
        customerSearchResults: action.payload.customerSearchResults
      };
    }
    case ActionTypes.SetNamAccessTokenRefresh: {
      return {
        ...state,
        user: {
          ...state.user,
          accessToken: action.payload
        }
      };
    }
    default: {
      return state;
    }
  }
}

function handleSetUserAttributes(
  state: AuthState,
  action: SetUserAttributes
) {
  let pk: CustomerPK;
  let customerName: string;
  let allEntitlements = new Array<Entitlement>;
  let roleScopedEntitlements = new Array<Entitlement>;
  if (0 < action.payload.entitlements?.length) {
    allEntitlements = [...action.payload.entitlements];
    const requiredRoles = [
      InventoryConstants.INVENTORY_OKTA_ROLES,
      InventoryConstants.RECIPE_OKTA_ROLES,
      InventoryConstants.ORDERING_OKTA_ROLES,
    ];
    roleScopedEntitlements = action.payload.entitlements
      .filter(perm => {
        return requiredRoles.some(role => perm.roleName.toLocaleLowerCase().includes(role))
      });

    if (action.payload.storedCustomerId) {
      const entitlementMatch = roleScopedEntitlements
        .find(perm => perm.customerPK.customerId === action.payload.storedCustomerId);
      if (entitlementMatch) {
        pk = entitlementMatch?.customerPK;
        customerName = entitlementMatch?.customerName;
      }
    }

    // If the user has only one customer location,
    // make it the selected customer
    const groupedPks = _.groupBy<Entitlement>(roleScopedEntitlements.filter(ent => ent.roleName.includes(action.payload.activeApplication)), 'customerPK.customerId');
    const customerIds = Object.keys(groupedPks);
    if (customerIds.length == 1) {
      pk = roleScopedEntitlements.filter(ent => ent.roleName.includes(action.payload.activeApplication))[0].customerPK;
      customerName = roleScopedEntitlements.filter(ent => ent.roleName.includes(action.payload.activeApplication))[0].customerName;
    }
  }

  return {
    ...state,
    user: {
      ...state.user,
      entitlements: roleScopedEntitlements
    },
    rolePermissions: action.payload.permissions,
    entitlements: allEntitlements,
    customerName,
    pk,
  };
}
