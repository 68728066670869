import { ApplicationUserRole } from '../lib/application-user-role.enum';
import { RecipeProfitCalculatorPaths } from '../lib/recipe-profit-calculator.model';
import { InventoryIcon } from '../lib/inventory-icon.model';

export enum applications {
  recipe = 'recipe',
  inventory = 'inventory'
}

export interface UnitOfMeasure {
  displayCode: string;
  descriptions: {
    single: {
      en: string;
      fr: string;
    }
    plural: {
      en: string;
      fr: string;
    }
  };
  isStandardWeight: boolean;
}

export enum entityTypes {
  CaCustomer = 'ca-customer',
  CaChain = 'ca-chain',
  CaGroup = 'ca-group',
  UsCustomer = 'us-customer',
  UsCustomerFamily = 'us-customer-family',
  SapCustomer = 'sap-customer',
  SapCommon = 'sap-common',
  SapGroup = 'sap-group'
}

export interface RecipeIcon {
  name: string;
  location: string;
}

export type RecipeFeaturePaths = {
  RECIPE_PROFIT_CALCULATOR: {
    callback: string,
    [ApplicationUserRole.Customer]: RecipeProfitCalculatorPaths,
    [ApplicationUserRole.Employee]: RecipeProfitCalculatorPaths,
  },
}

export class Constants {
  public static readonly SharedFeaturePaths = {
    ERROR: 'error',
    MANAGE_NONGFS_ITEMS_PATH: 'customer-items',
    MANAGE_NONGFS_ITEMS_PATHNAME: '/customer-items',
    GENERAL_LEDGER_ITEMS_PATH: 'general-ledger',
    GENERAL_LEDGER_ITEMS_PATHNAME: '/general-ledger',
    CUSTOMER_SELECTION_PATH: 'customer-unit-selection',
    CUSTOMER_SELECTION_PATHNAME: '/customer-unit-selection'
  };

  public static readonly RecipeFeaturePaths: RecipeFeaturePaths = {
    RECIPE_PROFIT_CALCULATOR: {
      callback: 'nam/callback',
      [ApplicationUserRole.Customer]: {
        RECIPE_PROFIT_CALCULATOR_PATH: 'recipe-profit-calculator',
        RECIPE_PROFIT_CALCULATOR_CUSTOMER_SELECTION_PATH: 'recipe-profit-calculator-customer-selection',
        RECIPE_PROFIT_CALCULATOR_CUSTOMER_SELECTION_PATHNAME: '/recipe-profit-calculator-customer-selection',
      },
      [ApplicationUserRole.Employee]: {
        RECIPE_PROFIT_CALCULATOR_PATH: 'recipe-calculator',
        RECIPE_PROFIT_CALCULATOR_CUSTOMER_SELECTION_PATH: 'recipe-calculator-customer-selection',
        RECIPE_PROFIT_CALCULATOR_CUSTOMER_SELECTION_PATHNAME: '/recipe-calculator-customer-selection',
      },
    }
  }

  public static readonly FeatureFlags = {
    GeneralLedger: 'FF_GENERAL_LEDGER',
    RecipeProfitCalculator: 'FF_RECIPE_PROFIT_CALCULATOR',
    AllowNonGfsItemsSearch: 'FF_SEARCH_FILTER_NONGFS_ITEMS',
    AllowRecipeItemsSearch: 'FF_SEARCH_FILTER_RECIPES_ITEMS'
  }
}

export class InventoryConstants {

  static infiniteScrollSettingPath = 'app.user.preferences.worksheet.infiniteScroll';

  private static DEBUGIDS = [
    '1125732', // Corn starch
    '1392021', // Thermometer
  ];

  public static APPLICATION_KEY = 'inventory';
  public static LANGUAGES = {
    DEFAULT: 'en_CA',
    ENGLISH: 'en_CA',
    FRENCH: 'fr_CA'
  };
  public static LANGUAGE_HEADER = 'Accept-Language';
  public static X_AUTH_TOKEN_HEADER = 'x-auth-token';
  public static AUTHORIZATION_HEADER = 'Authorization';
  public static LOGIN_LOCATION_HEADER = 'Login-Location';
  public static X_XSRF_HEADER = 'X-XSRF-Token';
  public static XSRF_TOKEN = 'XSRF-TOKEN';
  public static PREVIOUS_URL = 'previousUrl';
  public static LOGIN_URL = '/assets/login.html';
  public static LOGOUT_URL = '/api/logout';
  public static CONNECTIVITY_RETRY_UI_TIMER = 5000;
  public static CART_SUBMISSION_RETRY_UI_TIMER = 59000;
  public static SERVICE_RETRY_MAX_ATTEMPT = 6;
  public static QTY_MAX = 999;
  public static QTY_MIN = 0;
  public static CART_DEBOUNCE_REQUEST_INTERVAL = 750;
  public static LIST_DEBOUNCE_REQUEST_INTERVAL = 3000;
  public static PO_NUMBER_MAX_LENGTH = 20;
  public static ALLOWED_CHARACTERS_REGEX = '^[a-zA-Z0-9À-ÿ-.,!@$%&*‘’”“~[_ #"\'^()=+\\]}{:/]*$';
  public static LOADING_DISPLAY_DELAY = 500; // in ms
  public static NUMBER_OF_RESULTS = 50;
  public static DOUBLE_TAP_INTERVAL = 500; // in ms
  public static ANIMATION_TAP_INTERVAL = 250;
  public static MAX_INPUT_LENGTH = 1024;
  public static MAX_INVENTORY_PRODUCTS = 50;
  public static UPDATE_ITEM_REQUESTS_QUANTITY = 5;
  public static ITEM_QTY_REGEX = /^\d*\.?\d{0,2}$/g;
  public static REGEX_SPECIAL_CHARACTERS = /[.*+?^${}()|[\]\\]/g;
  public static PURCHASE_UNIT_LOCALIZED_REGEX = {
    en_CA: {
      INPUT_REGEX: /^(\d|,)*\.?\d{0,2}$/g,
      VALID_CURRENCY_REGEX: /^([0-9]{1,3}((,[0-9]{3})*|([0-9]{3})*))?(\.[0-9]{1,2})?$/,
      REPLACE_REGEX: /,/g,
      REPLACEMENTS: {
        ',': ''
      }
    },
    fr_CA: {
      INPUT_REGEX: /^(\d|\ )*,?\d{0,2}$/g,
      VALID_CURRENCY_REGEX: /^([0-9]{1,3}((\s[0-9]{3})*|([0-9]{3})*))?(,[0-9]{1,2})?$/,
      REPLACE_REGEX: /\ |,/g,
      REPLACEMENTS: {
        ' ': '',
        ',': '.'
      }
    }
  };
  public static WORKSHEET_ITEM_TYPES = {
    GFS: 'GFS',
    CUSTOM: 'CUSTOM',
    GENERAL: 'GENERAL',
    RECIPE: 'RECIPE',
  };
  public static COUNTING_UNIT_TYPES = {
    LITERAL: 'LITERAL',
    CUSTOM: 'CUSTOM',
    STANDARD: 'STANDARD',
    SAP: 'SAP'
  };
  public static GROUP_ENTITIES: string[] = [entityTypes.CaChain, entityTypes.CaGroup, entityTypes.UsCustomerFamily, entityTypes.SapCommon, entityTypes.SapGroup];
  public static CUSTOMER_ENTITIES: string[] = [entityTypes.CaCustomer, entityTypes.UsCustomer, entityTypes.SapCustomer];

  // Feature Paths
  public static INVENTORY_PATH = '/inventory';
  public static REPORTS_PATH = '/reports';
  public static PRODUCT_DETAILS_PATH = '/product';
  public static MY_GUIDES_PATH = '/my-guides';
  public static CART_PATH = '/cart';
  public static MOBILE_HAMBURGER_MENU_PATH = '/mobile-hamburger-menu';
  public static ORDERS_PATH = 'https://order.gfs.com/';
  public static RECIPE_PATH = 'https://recipe.gfs.com/';
  public static PAGE_NOT_FOUND_PATH = '/page-not-found';
  public static CUSTOMER_UNIT_SELECTION_PATH = '/customer-unit-selection';
  public static CATALOG_PATH = '/catalog';

  // API URLs
  public static SEARCH_URL = '/search';
  public static REVIEW_ORDER_URL = '/review-order';
  public static ORDER_CONFIRMATION_URL = '/order-confirmation';

  // Analytics event properties
  public static ANALYTICS_CATEGORY = 'warning';
  public static ANALYTICS_ACTION_DISPLAYED = 'displayed';
  public static CASE_EACH_CONVERSION_LABEL = 'case unit conversion message - ';
  public static WARNING_UNIT_CONVERSION = 'PRODUCT.WARNING.UNIT_CASE_CONVERSION.TITLE';
  public static LARGE_QUANTITY_LABEL = 'large quantity message - ';
  public static WARNING_LARGE_QUANTITY = 'PRODUCT.WARNING.QUANTITY_THRESHOLD.TITLE';
  public static REPEATING_DIGITS_LABEL = 'repeating digit message - ';
  public static WARNING_REPEATING_DIGITS = 'PRODUCT.WARNING.REPEATING_DIGITS.TITLE';

  // Custom Dimensions
  public static CUSTOMER_UNIT_CD_INDEX = 'customDimension4';
  public static USER_ID_CD_INDEX = 'customDimension46';

  // Assets
  public static IMAGES = {
    SMALL: 'Small',
    MEDIUM: 'Medium',
    LARGE: 'Large'
  };
  public static CategoryImagePath = 'assets/images/catalog/';

  // NOTE: Make sure breakpoints match the ones defined in variables.scss file
  public static DEVICE = {
    DESKTOP: 'DESKTOP',
    MOBILE: 'MOBILE'
  };

  public static DEVICE_DPI_RATIO = {
    SMALL: 1,
    MEDIUM: 2,
    LARGE: 3
  };

  public static OMIT_HEADER_ROUTES = [
    InventoryConstants.MOBILE_HAMBURGER_MENU_PATH,
    InventoryConstants.REVIEW_ORDER_URL
  ];

  public static HERO_PAGE_ROUTES = [
    InventoryConstants.MY_GUIDES_PATH,
    InventoryConstants.ORDERS_PATH,
    InventoryConstants.CATALOG_PATH
  ];

  public static CENTERED_PAGE_ROUTES = [InventoryConstants.REVIEW_ORDER_URL];

  // List style pages that should maintain a white background rather than the light gray default
  public static WHITE_BACKGROUND_ROUTES = [
    InventoryConstants.ORDER_CONFIRMATION_URL,
    InventoryConstants.REVIEW_ORDER_URL,
    InventoryConstants.CART_PATH,
    InventoryConstants.MOBILE_HAMBURGER_MENU_PATH,
    InventoryConstants.PRODUCT_DETAILS_PATH,
    InventoryConstants.ORDERS_PATH,
    InventoryConstants.PAGE_NOT_FOUND_PATH
  ];

  public static CREATE_CUSTOM_GUIDE_MODAL_ID = 'create-custom-guide';
  public static CUSTOM_GUIDE_NAME_MAX_LENGTH = 40;

  public static RENAME_CUSTOM_GUIDE_MODAL_ID = 'rename-custom-guide';
  public static SET_CRITICAL_ITEM_GUIDE_MODAL_ID = 'set-critical-item-guide';
  public static CRITICAL_ITEM_HELPER_MODAL_ID = 'critical-item-helper';

  public static CREATE_CATEGORY_MODAL_ID = 'create-custom-category';
  public static CUSTOM_CATEGORY_MAX_LENGTH = 25;
  public static RENAME_CATEGORY_MODAL_ID = 'rename-custom-category';

  public static LOADING_MODAL_ID = 'loading-modal';
  public static ERROR_MODAL_ID = 'error-modal';

  public static MOBILE_SEARCH_MODAL_ID = 'mobile-search';

  public static TOAST_MESSAGE_DURATION_DEFAULT = 4000; // ms

  public static UNASSIGNED_EN_CA = 'Unassigned';
  public static UNASSIGNED_FR_CA = 'Non-assignés';

  public static ORDER_GUIDE_EN_CA = 'Order Guide';
  public static ORDER_GUIDE_FR_CA = 'Fiche de commande';

  public static VIRTUAL_SCROLL_ANIMATION_TIME = 0;

  public static EMPTY_CATEGORY_OFFERING_ID_PREFIX = 'PRODUCT-';

  public static INVENTORY_OKTA_ROLES = 'com.gfs:inventory';
  public static RECIPE_OKTA_ROLES = 'com.gfs:recipe';
  public static RECIPE_CALCULATOR_OKTA_ROLES = 'com.gfs:recipe.calculator';
  public static ORDERING_OKTA_ROLES = 'com.gfs:order';

  public static RECIPE_ROLE = 'com.gfs:recipe.access';
  public static ORDERING_ROLE = 'com.gfs:order.access';

  public static APPLICATION_ROLESET_MAPPING = {
    inventory: InventoryConstants.INVENTORY_OKTA_ROLES,
    recipe: InventoryConstants.RECIPE_OKTA_ROLES
  };
  public static INVENTORY_OKTA_ROLE = [
    'com.gfs:inventory.admin',
    'com.gfs:inventory.editor2',
    'com.gfs:inventory.editor1',
    'com.gfs:inventory.counter2',
    'com.gfs:inventory.counter1',
    'com.gfs:inventory.access'
  ];

  public static INVENTORY_LINKS = [
    {
      link: InventoryConstants.INVENTORY_PATH,
      isActive: true,
      text: 'HEADER.MENU.INVENTORY_COUNTS'
    },
    {
      link: InventoryConstants.REPORTS_PATH,
      isActive: true,
      text: 'HEADER.MENU.REPORTS'
    },
  ];

  public static INVENTORY_ROLE_PERMISSIONS = {
    inventoryCounts: {
      tripleDotMenu: [
        'inventory.worksheets.delete',
        'inventory.worksheets.closed.update'
      ]
    },
    header: {
      resetWorksheet: ['inventory.worksheets.reset']
    },
    pricing: 'pricing',
    inventoryWorksheet: {
      addItems: [
        'generalItems.generalItems.create',
        'inventory.worksheetItems.create'
      ],
      createStorageArea: 'inventory.storageAreas.create',
      deleteStorageArea: 'inventory.storageAreas.delete',
      updateStorageArea: 'inventory.storageAreas.update',
      updateStorageAreaOrder: 'inventory.worksheets.updateStorageAreaOrder',
      itemTripleDot: [
        'generalItems.generalItems.create',
        'inventory.worksheetItems.create',
        'inventory.worksheetItems.delete'
      ],
      updateWorksheetItemUnit: 'inventory.worksheetItems.updateUnit'
    },
    reports: {
      inventoryValue: 'inventory.reports.getInventoryValue'
    }
  };

  public static GFS_APPLICATIONS = [{
    label: 'APPLICATIONS.ORDERING',
    url: InventoryConstants.ORDERS_PATH
  }, {
    label: 'APPLICATIONS.INVENTORY',
    selected: true,
    url: '/'
  }, {
    label: 'APPLICATIONS.RECIPE',
    url: InventoryConstants.RECIPE_PATH
  }];

  public static icons: { [iconName: string]: InventoryIcon } = {
    edit_icon: {
      name: 'edit-icon',
      location: './assets/images/edit-icon.svg'
    },
    info_icon: {
      name: 'info_icon',
      location: './assets/images/info_icon.svg'
    },
    info_icon_hover: {
      name: 'info_icon_hover',
      location: './assets/images/info_icon_hover.svg'
    },
    info_icon_black: {
      name: 'info_icon_black',
      location: './assets/images/info_icon_black.svg'
    },
    trash_icon: {
      name: 'trash-icon',
      location: './assets/images/trash-icon.svg'
    },
    plus_icon: {
      name: 'plus-icon',
      location: './assets/images/plus-icon.svg'
    },
    plus_icon_gray: {
      name: 'plus-icon-gray',
      location: './assets/images/plus-icon-gray.svg'
    },
    triple_dot_menu: {
      name: 'triple-dot-menu',
      location: './assets/images/triple-dot-menu.svg'
    },
    triple_dot_menu_sideways: {
      name: 'triple-dot-menu-sideways',
      location: './assets/images/triple-dot-menu-sideways.svg'
    },
    add: {
      name: 'add',
      location: './assets/images/add.svg'
    },
    subtract: {
      name: 'subtract',
      location: './assets/images/subtract.svg'
    },
    subtract_disabled: {
      name: 'subtract-disabled',
      location: './assets/images/subtract-disabled.svg'
    },
    close_icon: {
      name: 'close-icon',
      location: './assets/images/close-icon.svg'
    },
    arrow_up_left: {
      name: 'arrow-up-left',
      location: './assets/images/arrow-up-left.svg'
    },
    search_icon: {
      name: 'search_icon',
      location: './assets/images/search-icon.svg'
    },
    clear_icon: {
      name: 'clear_icon',
      location: './assets/images/clear_filter.svg'
    },
    search_gray_background: {
      name: 'search-gray-background',
      location: './assets/images/search-gray-background.svg'
    },
    refresh_icon: {
      name: 'refresh-icon',
      location: './assets/images/refresh-icon.svg'
    },
    binoculars_icon: {
      name: 'binoculars-icon',
      location: './assets/images/binoculars.svg'
    },
    back_arrow: {
      name: 'back-arrow',
      location: './assets/images/back-arrow.svg'
    },
    arrow: {
      name: 'arrow',
      location: './assets/images/arrow.svg'
    },
    connection_status: {
      name: 'connection-status',
      location: './assets/images/connection-status.svg'
    },
    print: {
      name: 'print',
      location: './assets/images/print-icon.svg'
    },
    language: {
      name: 'language',
      location: './assets/images/language.svg'
    },
    shop: {
      name: 'shop',
      location: './assets/images/store-icon-gray.svg'
    },
    cart_icon: {
      name: 'cart_icon',
      location: './assets/images/cart-icon-blue.svg'
    },
    cart_icon_gray: {
      name: 'cart_icon_gray',
      location: './assets/images/cart-icon-gray.svg'
    },
    calendar_icon: {
      name: 'calendar_icon',
      location: './assets/images/calendar-icon.svg'
    },
    unavailable: {
      name: 'unavailable',
      location: './assets/images/unavailable.svg'
    },
    hamburger_icon: {
      name: 'hamburger_icon',
      location: './assets/images/hamburger-icon.svg'
    },
    upload_icon: {
      name: 'upload_icon',
      location: './assets/images/upload_icon.svg'
    },
    warning: {
      name: 'warning-icon',
      location: './assets/images/item-error-icon.svg'
    },
    open_in_new: {
      name: 'open_in_new',
      location: './assets/images/open-in-new.svg'
    }
  };

  // Measurement Constants
  public static STANDARD_WEIGHT_UNIT_KG = 'metric_weight_kg';
  public static STANDARD_WEIGHT_UNIT_LB = 'usCustomary_weight_lb';
  public static STANDARD_DEFAULT_PARENT_UNIT = InventoryConstants.STANDARD_WEIGHT_UNIT_KG;
  public static SAP_WEIGHT_UOM_KG = 'KG';
  public static SAP_WEIGHT_UOM_LB = 'LB';
  public static SAP_UOM_CASE = 'CS';
  public static SAP_UOM_EACH = 'EA';
  public static LITERAL_UOM_CASE = 'case';
  public static LITERAL_UOM_UNIT = 'unit';

  public static DISPLAY_WORKSHEET_COUNTING_UNIT = 'inventoryWorksheetCountingUnit';
  public static UNIT_TYPE_LITERAL = InventoryConstants.COUNTING_UNIT_TYPES.LITERAL;
  public static UNIT_TYPE_STANDARD = InventoryConstants.COUNTING_UNIT_TYPES.STANDARD;
  public static UNIT_TYPE_SAP = InventoryConstants.COUNTING_UNIT_TYPES.SAP;
  public static UNIT_TYPE_CUSTOM = InventoryConstants.COUNTING_UNIT_TYPES.CUSTOM;

  public static GLOBAL_UOMS: UnitOfMeasure[] = [
    {
      displayCode: 'PT',
      descriptions: {
        single: {
          en: 'Pint, US liquid',
          fr: 'Pinte,É-U'
        },
        plural: {
          en: 'Pints US L',
          fr: 'Pintes,É-U'
        }
      },
      isStandardWeight: false,
    },
    {
      displayCode: 'G',
      descriptions: {
        single: {
          en: 'Gram',
          fr: 'Gramme'
        },
        plural: {
          en: 'Grams',
          fr: 'Grammes'
        }
      },
      isStandardWeight: true,
    },
    {
      displayCode: 'UNA',
      descriptions: {
        single: {
          en: 'Unit Avg',
          fr: 'Moyenne unité'
        },
        plural: {
          en: 'Units Avg',
          fr: 'Moyenne un'
        }
      },
      isStandardWeight: false,
    },
    {
      displayCode: 'LBA',
      descriptions: {
        single: {
          en: 'Pound Avg',
          fr: 'Moyenne livre'
        },
        plural: {
          en: 'Pounds Avg',
          fr: 'Moyenne li'
        }
      },
      isStandardWeight: true,
    },
    {
      displayCode: 'YD',
      descriptions: {
        single: {
          en: 'Yards',
          fr: 'Mètre'
        },
        plural: {
          en: 'Yards',
          fr: 'Mètres'
        }
      },
      isStandardWeight: false,
    },
    {
      displayCode: 'L',
      descriptions: {
        single: {
          en: 'Liter',
          fr: 'Litre'
        },
        plural: {
          en: 'Liters',
          fr: 'Litres'
        }
      },
      isStandardWeight: false,
    },
    {
      displayCode: 'M',
      descriptions: {
        single: {
          en: 'Meter',
          fr: 'Mètre'
        },
        plural: {
          en: 'Meters',
          fr: 'Mètres'
        }
      },
      isStandardWeight: false,
    },
    {
      displayCode: 'FT',
      descriptions: {
        single: {
          en: 'Foot',
          fr: 'Pied'
        },
        plural: {
          en: 'Feet',
          fr: 'Pieds'
        }
      },
      isStandardWeight: false,
    },
    {
      displayCode: 'SH',
      descriptions: {
        single: {
          en: 'Sheet',
          fr: 'Feuille'
        },
        plural: {
          en: 'Sheets',
          fr: 'Feuilles'
        }
      },
      isStandardWeight: false,
    },
    {
      displayCode: 'DZ',
      descriptions: {
        single: {
          en: 'Dozen',
          fr: 'Douzaine'
        },
        plural: {
          en: 'Dozen',
          fr: 'Douzaine'
        }
      },
      isStandardWeight: false,
    },
    {
      displayCode: 'UN',
      descriptions: {
        single: {
          en: 'Unit',
          fr: 'Unité'
        },
        plural: {
          en: 'Units',
          fr: 'Unité'
        }
      },
      isStandardWeight: false,
    },
    {
      displayCode: 'GA',
      descriptions: {
        single: {
          en: 'Gram Avg',
          fr: 'Moyenne gramme'
        },
        plural: {
          en: 'Grams Avg',
          fr: 'Moyenne gm'
        }
      },
      isStandardWeight: true,
    },
    {
      displayCode: 'KG',
      descriptions: {
        single: {
          en: 'Kilogram',
          fr: 'Kilogramme'
        },
        plural: {
          en: 'Kilograms',
          fr: 'Kilogrames'
        }
      },
      isStandardWeight: true,
    },
    {
      displayCode: 'EA',
      descriptions: {
        single: {
          en: 'Each',
          fr: 'unité'
        },
        plural: {
          en: 'Eaches',
          fr: 'unité'
        }
      },
      isStandardWeight: false,
    },
    {
      displayCode: 'ML',
      descriptions: {
        single: {
          en: 'Milliliter',
          fr: 'Millilitre'
        },
        plural: {
          en: 'Milliliter',
          fr: 'Millilitre'
        }
      },
      isStandardWeight: false,
    },
    {
      displayCode: 'ST',
      descriptions: {
        single: {
          en: 'Set',
          fr: 'Ensemble'
        },
        plural: {
          en: 'Sets',
          fr: 'Ensembles'
        }
      },
      isStandardWeight: false,
    },
    {
      displayCode: '\'',
      descriptions: {
        single: {
          en: 'Inch',
          fr: 'Pouce'
        },
        plural: {
          en: 'Inches',
          fr: 'Pouces'
        }
      },
      isStandardWeight: false,
    },
    {
      displayCode: 'QT',
      descriptions: {
        single: {
          en: 'Quart, US liquid',
          fr: 'Litre,É-U'
        },
        plural: {
          en: 'QuartsUS L',
          fr: 'Litres,É-U'
        }
      },
      isStandardWeight: false,
    },
    {
      displayCode: 'KGA',
      descriptions: {
        single: {
          en: 'Kilogram Average',
          fr: 'Moyenne kilogramme'
        },
        plural: {
          en: 'Kilograms',
          fr: 'Moyenne kg'
        }
      },
      isStandardWeight: true,
    },
    {
      displayCode: 'BAG',
      descriptions: {
        single: {
          en: 'Bag',
          fr: 'Sac'
        },
        plural: {
          en: 'Bags',
          fr: 'Sac'
        }
      },
      isStandardWeight: false,
    },
    {
      displayCode: 'OZ',
      descriptions: {
        single: {
          en: 'Ounce Avg',
          fr: 'Once'
        },
        plural: {
          en: 'Ounces Avg',
          fr: 'Onces'
        }
      },
      isStandardWeight: true,
    },
    {
      displayCode: 'CO',
      descriptions: {
        single: {
          en: 'Count',
          fr: 'Nombre'
        },
        plural: {
          en: 'Count',
          fr: 'Nombre'
        }
      },
      isStandardWeight: false,
    },
    {
      displayCode: 'ROL',
      descriptions: {
        single: {
          en: 'Roll',
          fr: 'Rouleau'
        },
        plural: {
          en: 'Rolls',
          fr: 'Rouleau'
        }
      },
      isStandardWeight: false,
    },
    {
      displayCode: 'CS',
      descriptions: {
        single: {
          en: 'Case',
          fr: 'Boîte'
        },
        plural: {
          en: 'Cases',
          fr: 'Boîte'
        }
      },
      isStandardWeight: false,
    },
    {
      displayCode: 'PAA',
      descriptions: {
        single: {
          en: 'Pair',
          fr: 'Paire'
        },
        plural: {
          en: 'Pairs',
          fr: 'Paire'
        }
      },
      isStandardWeight: false,
    },
    {
      displayCode: 'PC',
      descriptions: {
        single: {
          en: 'Piece',
          fr: 'Pièce'
        },
        plural: {
          en: 'Pieces',
          fr: 'Pièce'
        }
      },
      isStandardWeight: false,
    },
    {
      displayCode: 'PAC',
      descriptions: {
        single: {
          en: 'Pack',
          fr: 'Paquet'
        },
        plural: {
          en: 'Pack',
          fr: 'Paquet'
        }
      },
      isStandardWeight: false,
    },
    {
      displayCode: 'GAL',
      descriptions: {
        single: {
          en: 'US Gallon',
          fr: 'Gallon US'
        },
        plural: {
          en: 'US Gallons',
          fr: 'Gallons US'
        }
      },
      isStandardWeight: false,
    },
    {
      displayCode: 'LB',
      descriptions: {
        single: {
          en: 'Pound',
          fr: 'Livre'
        },
        plural: {
          en: 'Pounds',
          fr: 'Livre'
        }
      },
      isStandardWeight: true,
    },
    {
      displayCode: 'BNC',
      descriptions: {
        single: {
          en: 'Bunch',
          fr: 'Bouquet'
        },
        plural: {
          en: 'Bunches',
          fr: 'Bouquets'
        }
      },
      isStandardWeight: false,
    },
    {
      displayCode: 'OZA',
      descriptions: {
        single: {
          en: 'Ounce Avg',
          fr: 'Moyenne once'
        },
        plural: {
          en: 'Ounces Avg',
          fr: 'Moyenne on'
        }
      },
      isStandardWeight: true,
    },
    {
      displayCode: 'PAI',
      descriptions: {
        single: {
          en: 'Pail',
          fr: 'Seau'
        },
        plural: {
          en: 'Pails',
          fr: 'Seaux'
        }
      },
      isStandardWeight: false,
    },
    {
      displayCode: 'PK',
      descriptions: {
        single: {
          en: 'Package',
          fr: 'Emballage'
        },
        plural: {
          en: 'Packages',
          fr: 'Emballage'
        }
      },
      isStandardWeight: false,
    }
  ];
  public static GLOBAL_STANDARD_WEIGHT_CODES: string[] = InventoryConstants.GLOBAL_UOMS
    .filter(uom => uom.isStandardWeight)
    .map(uom => uom.displayCode);
  public static DEBUG = (id: string): boolean =>
    InventoryConstants.DEBUGIDS.some(debugId => debugId === id);
}

export class RecipeConstants {
  public static NEW_ENTITY_ID_PLACEHOLDER = 'NEW_ENTITY_ID_PLACEHOLDER';
  public static APPLICATION_KEY = 'recipe';
  public static LANGUAGES = {
    DEFAULT: 'en_CA',
    EN_CA: 'en_CA',
    FR_CA: 'fr_CA'
  };
  public static BATCH_YIELD_QTY_REGEX = /^(([0-9]+)|([0-9]*[\.\,][0-9][0-9]?[0-9]?)|([0-9]+[\/\\][0-9]+)|([0-9]+[\ ][0-9]+[\/\\][0-9]+))$$/;
  public static BATCH_YIELD_VALID_INPUT_ARRAY = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '\\', '/', '.', ','];
  public static MEASUREMENT_TYPE = {
    WEIGHT: 'weight',
    VOLUME: 'volume',
    COUNT: 'count'
  };
  public static IMPERIAL_MEASUREMENT_SYSTEM = 'imperial';
  public static METRIC_MEASUREMENT_SYSTEM = 'metric';
  public static BATCH_RECIPE_MEASUREMENT_SYSTEM = 'batchRecipe';
  public static MENU_ITEM_MEASUREMENT_SYSTEM = 'menuItem';

  // Many of the below constants are not being used in this app
  // TODO: confirm which constants are needed

  public static STATUS = {
    ACTIVE: 'active',
    DELETED: 'deleted'
  };

  public static UNSAVED_RECIPE_ID = 'new-unsaved-recipe'
  public static LANGUAGE_HEADER = 'Accept-Language';
  public static X_AUTH_TOKEN_HEADER = 'x-auth-token';
  public static AUTHORIZATION_HEADER = 'Authorization';
  public static LOGIN_LOCATION_HEADER = 'Login-Location';
  public static X_XSRF_HEADER = 'X-XSRF-Token';
  public static XSRF_TOKEN = 'XSRF-TOKEN';
  public static PREVIOUS_URL = 'previousUrl';
  public static LOGIN_URL = '/assets/login.html';
  public static LOGOUT_URL = '/api/logout';
  public static CONNECTIVITY_RETRY_UI_TIMER = 5000;
  public static CART_SUBMISSION_RETRY_UI_TIMER = 59000;
  public static SERVICE_RETRY_MAX_ATTEMPT = 6;
  public static QTY_MAX = 999;
  public static QTY_MIN = 0;
  public static CART_DEBOUNCE_REQUEST_INTERVAL = 750;
  public static LIST_DEBOUNCE_REQUEST_INTERVAL = 3000;
  public static PO_NUMBER_MAX_LENGTH = 20;
  public static ALLOWED_CHARACTERS_REGEX = '^[a-zA-Z0-9À-ÿ-.,!@$%&*‘’”“~[_ #"\'^()=+\\]}{:/]*$';
  public static LOADING_DISPLAY_DELAY = 500; // in ms
  public static NUMBER_OF_RESULTS = 50;
  public static DOUBLE_TAP_INTERVAL = 500; // in ms
  public static ANIMATION_TAP_INTERVAL = 250;
  public static MAX_INPUT_LENGTH = 1024;

  // Feature Paths
  public static PRODUCT_DETAILS_PATH = '/product';
  public static MY_GUIDES_PATH = '/my-guides';
  public static CART_PATH = '/cart';
  public static MOBILE_HAMBURGER_MENU_PATH = '/mobile-hamburger-menu';
  public static ORDERS_PATH = '/orders';
  public static PAGE_NOT_FOUND_PATH = '/page-not-found';
  public static CUSTOMER_UNIT_SELECTION_PATH = '/customer-unit-selection';
  public static CATALOG_PATH = '/catalog';
  public static MY_RECIPES_PATH = '/category';
  public static MY_RECIPES_REPORTS_PATH = '/recipe-reports';
  public static MY_RECIPES_EMPLOYEE_PATH = '/recipe-calculator';
  // API URLs
  public static SEARCH_URL = '/search';
  public static REVIEW_ORDER_URL = '/review-order';
  public static ORDER_CONFIRMATION_URL = '/order-confirmation';

  // Analytics event properties
  public static ANALYTICS_CATEGORY = 'warning';
  public static ANALYTICS_ACTION_DISPLAYED = 'displayed';
  public static CASE_EACH_CONVERSION_LABEL = 'case unit conversion message - ';
  public static WARNING_UNIT_CONVERSION = 'PRODUCT.WARNING.UNIT_CASE_CONVERSION.TITLE';
  public static LARGE_QUANTITY_LABEL = 'large quantity message - ';
  public static WARNING_LARGE_QUANTITY = 'PRODUCT.WARNING.QUANTITY_THRESHOLD.TITLE';
  public static REPEATING_DIGITS_LABEL = 'repeating digit message - ';
  public static WARNING_REPEATING_DIGITS = 'PRODUCT.WARNING.REPEATING_DIGITS.TITLE';

  public static RECIPE_ROLE = 'cn=GordonRecipeAccess,ou=Gordon Recipe,ou=CA Customer,ou=Applications,ou=ebz,o=gfs';
  public static RECIPE_OKTA_ROLE = 'com.gfs:recipe';

  public static RECIPE_LINKS = [
    {
      link: RecipeConstants.MY_RECIPES_PATH,
      isActive: true,
      text: 'TITLE',
      roles: [entityTypes.CaCustomer, entityTypes.UsCustomer, entityTypes.SapCustomer]
    },
    {
      link: RecipeConstants.MY_RECIPES_REPORTS_PATH,
      isActive: true,
      text: 'RECIPE_SUMMARY_REPORTING.REPORTS',
      roles: [entityTypes.CaCustomer, entityTypes.UsCustomer]
    },
  ];
  public static RECIPE_EMPLOYEE_LINKS = [
    {
      link: RecipeConstants.MY_RECIPES_EMPLOYEE_PATH,
      isActive: true,
      text: 'TITLE',
      roles: [entityTypes.CaCustomer, entityTypes.UsCustomer]
    },
    {
      link: RecipeConstants.MY_RECIPES_REPORTS_PATH,
      isActive: true,
      text: 'RECIPE_SUMMARY_REPORTING.REPORTS',
      roles: [entityTypes.CaCustomer, entityTypes.UsCustomer]
    },
  ];

  // Assets
  public static IMAGES = {
    SMALL: 'Small',
    MEDIUM: 'Medium',
    LARGE: 'Large'
  };
  public static icons: { [iconName: string]: RecipeIcon } = {
    arrow: {
      name: 'arrow-icon',
      location: './assets/images/arrow.svg'
    },
    arrowLeft: {
      name: 'arrow-left',
      location: './assets/images/arrow-left.svg'
    },
    arrow_gray: {
      name: 'arrow-gray',
      location: './assets/images/arrow-gray.svg'
    },
    arrow_up_left: {
      name: 'arrow-up-left',
      location: './assets/images/arrow-up-left.svg'
    },
    alert: {
      name: 'alert-icon',
      location: './assets/images/alert-icon.svg'
    },
    calendar: {
      name: 'calendar-icon',
      location: './assets/images/ic-calendar-v2.svg'
    },
    item_usage_calendar: {
      name: 'item-usage-calendar',
      location: './assets/images/item-usage-calendar-v2.svg'
    },
    store: {
      name: 'store-icon',
      location: './assets/images/ic-store.svg'
    },
    number: {
      name: 'number-icon',
      location: './assets/images/number-icon-v2.svg'
    },
    empty: {
      name: 'empty-icon',
      location: './assets/images/empty-v2.svg'
    },
    success: {
      name: 'success-icon',
      location: './assets/images/success-icon.svg'
    },
    error: {
      name: 'error-icon',
      location: './assets/images/error-icon.svg'
    },
    warning: {
      name: 'warning-icon',
      location: './assets/images/item-error-icon.svg'
    },
    invoiced: {
      name: 'invoiced-icon',
      location: './assets/images/invoiced-icon.svg'
    },
    credit_order: {
      name: 'credit-order-icon',
      location: './assets/images/credit-order-icon.svg'
    },
    new_window: {
      name: 'new-window-icon',
      location: './assets/images/new-window-icon.svg'
    },
    triple_dot_menu: {
      name: 'triple_dot_menu',
      location: './assets/images/triple-dot-menu.svg'
    },
    list_active: {
      name: 'list_active',
      location: './assets/images/list-icon-active.svg'
    },
    list_inactive: {
      name: 'list_inactive',
      location: './assets/images/list-icon-inactive.svg'
    },
    grid_active: {
      name: 'grid_active',
      location: './assets/images/grid-icon-active.svg'
    },
    grid_inactive: {
      name: 'grid_inactive',
      location: './assets/images/grid-icon-inactive.svg'
    },
    import_icon: {
      name: 'import_icon',
      location: './assets/images/import-icon-v2.svg'
    },
    export: {
      name: 'export',
      location: './assets/images/export-icon.svg'
    },
    print: {
      name: 'print',
      location: './assets/images/print-icon.svg'
    },
    add_icon: {
      name: 'add-icon',
      location: './assets/images/add-icon.svg'
    },
    category_up_active: {
      name: 'category_up_active',
      location: './assets/images/category_up.svg'
    },
    category_down_active: {
      name: 'category_down_active',
      location: './assets/images/category_down.svg'
    },
    triple_dot_menu_white: {
      name: 'triple_dot_menu_white',
      location: './assets/images/triple-dot-menu-white.svg'
    },
    cart_icon: {
      name: 'cart_icon',
      location: './assets/images/cart-icon-blue.svg'
    },
    cart_icon_gray: {
      name: 'cart_icon_gray',
      location: './assets/images/cart-icon-gray.svg'
    },
    import_guide_document: {
      name: 'import-guide-document',
      location: './assets/images/import-guide-document.svg'
    },
    hand_string: {
      name: 'hand_string',
      location: './assets/images/hand-string.svg'
    },
    hand_card_en: {
      name: 'hand_card_en',
      location: './assets/images/hand-card-en.svg'
    },
    hand_card_fr: {
      name: 'hand_card_fr',
      location: './assets/images/hand-card-fr.svg'
    },
    critical_item_star: {
      name: 'critical_item_star',
      location: './assets/images/critical-item-star-v2.svg'
    },
    edit_icon: {
      name: 'edit-icon',
      location: './assets/images/edit-icon.svg'
    },
    trash_icon: {
      name: 'trash-icon',
      location: './assets/images/trash-icon.svg'
    },
    email: {
      name: 'email',
      location: './assets/images/email.svg'
    },
    plus: {
      name: 'plus',
      location: './assets/images/plus.svg'
    },
    full_error: {
      name: 'full-error',
      location: './assets/images/full-error.svg'
    },
    exception_icon: {
      name: 'exception-icon',
      location: './assets/images/exception-icon.svg'
    },
    unavailable: {
      name: 'unavailable',
      location: './assets/images/unavailable.svg'
    },
    copy_items: {
      name: 'copy-items',
      location: './assets/images/copy-items.svg'
    },
    plus_icon: {
      name: 'plus-icon',
      location: './assets/images/plus-icon.svg'
    },
    plus_icon_gray: {
      name: 'plus-icon-gray',
      location: './assets/images/plus-icon-gray.svg'
    },
    search_icon: {
      name: 'search_icon',
      location: './assets/images/search-icon.svg'
    },
    search_gray_background: {
      name: 'search-gray-background',
      location: './assets/images/search-gray-background.svg'
    },
    clock: {
      name: 'clock-icon',
      location: './assets/images/clock-icon.svg'
    },
    refresh_icon: {
      name: 'refresh-icon',
      location: './assets/images/refresh-icon.svg'
    },
    binoculars_icon: {
      name: 'binoculars-icon',
      location: './assets/images/binoculars.svg'
    },
    menu_item: {
      name: 'menu-item',
      location: './assets/images/menu-item.svg'
    },
    batch_recipe: {
      name: 'batch-recipe',
      location: './assets/images/batch-recipe.svg'
    },
    info_icon: {
      name: 'info',
      location: './assets/images/info_icon.svg'
    },
    bread: {
      name: 'bread',
      location: './assets/images/bread.svg'
    },
    cake: {
      name: 'cake',
      location: './assets/images/cake.svg'
    },
    dough: {
      name: 'dough',
      location: './assets/images/dough.svg'
    },
    meat: {
      name: 'meat',
      location: './assets/images/meat.svg'
    },
    sauce: {
      name: 'sauce',
      location: './assets/images/sauce.svg'
    },
    soup: {
      name: 'soup',
      location: './assets/images/soup.svg'
    },
    connection_status: {
      name: 'connection-status',
      location: './assets/images/connection-status.svg'
    },
    language: {
      name: 'language',
      location: './assets/images/language.svg'
    },
  };
  public static CategoryImagePath = 'assets/images/catalog/';

  // NOTE: Make sure breakpoints match the ones defined in variables.scss file
  public static DEVICE = {
    DESKTOP: 'DESKTOP',
    MOBILE: 'MOBILE'
  };

  public static DEVICE_DPI_RATIO = {
    SMALL: 1,
    MEDIUM: 2,
    LARGE: 3
  };

  public static OMIT_HEADER_ROUTES = [
    RecipeConstants.MOBILE_HAMBURGER_MENU_PATH,
    RecipeConstants.REVIEW_ORDER_URL
  ];

  public static HERO_PAGE_ROUTES = [
    RecipeConstants.MY_GUIDES_PATH,
    RecipeConstants.ORDERS_PATH,
    RecipeConstants.CATALOG_PATH
  ];

  public static CENTERED_PAGE_ROUTES = [RecipeConstants.REVIEW_ORDER_URL];

  // List style pages that should maintain a white background rather than the light gray default
  public static WHITE_BACKGROUND_ROUTES = [
    RecipeConstants.ORDER_CONFIRMATION_URL,
    RecipeConstants.REVIEW_ORDER_URL,
    RecipeConstants.CART_PATH,
    RecipeConstants.MOBILE_HAMBURGER_MENU_PATH,
    RecipeConstants.PRODUCT_DETAILS_PATH,
    RecipeConstants.ORDERS_PATH,
    RecipeConstants.PAGE_NOT_FOUND_PATH
  ];

  public static CREATE_CUSTOM_GUIDE_MODAL_ID = 'create-custom-guide';
  public static CUSTOM_GUIDE_NAME_MAX_LENGTH = 40;

  public static RENAME_CUSTOM_GUIDE_MODAL_ID = 'rename-custom-guide';
  public static SET_CRITICAL_ITEM_GUIDE_MODAL_ID = 'set-critical-item-guide';
  public static CRITICAL_ITEM_HELPER_MODAL_ID = 'critical-item-helper';

  public static CREATE_CATEGORY_MODAL_ID = 'create-custom-category';
  public static CUSTOM_CATEGORY_MAX_LENGTH = 25;
  public static RENAME_CATEGORY_MODAL_ID = 'rename-custom-category';

  public static LOADING_MODAL_ID = 'loading-modal';

  public static MOBILE_SEARCH_MODAL_ID = 'mobile-search';

  public static TOAST_MESSAGE_DURATION_DEFAULT = 4000; // ms

  public static UNASSIGNED_EN_CA = 'Unassigned';
  public static UNASSIGNED_FR_CA = 'Non-assignés';

  public static ORDER_GUIDE_EN_CA = 'Order Guide';
  public static ORDER_GUIDE_FR_CA = 'Fiche de commande';

  public static VIRTUAL_SCROLL_ANIMATION_TIME = 0;

  public static EMPTY_CATEGORY_OFFERING_ID_PREFIX = 'PRODUCT-';
  public static RECIPE_INGREDIENT_DATA_PATH_BASE = 'ingredients';
}
