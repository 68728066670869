import { Inject, Injectable } from '@angular/core';
import { AppConfigService } from '../app-config/app-config.service';
import { Constants, InventoryConstants, applications } from '@gfs/constants';
import {
  ApplicationUserRole,
  CustomerPK,
  Entitlement,
  RECIPE_PROFIT_CALCULATOR_CONFIG,
  RecipeProfitCalculatorConfig
} from '@gfs/shared-models';
import { InjectionTokens, RedirectUri } from '@gfs/shared-services';
import { Store } from '@ngrx/store';
import { AppState } from '@gfs/store/inventory/reducers';
import { Observable, forkJoin, map, of } from 'rxjs';
import { CustomerUtilService } from '../customer-util.service';

export interface GFSRoute {
  label: string;
  key: string;
  url: string;
  redirect?: RedirectUri;
  roles: string[];
  selected?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class GfsApplicationsService {
  private readonly gfsApplications: GFSRoute[];

  settings = this.configService.getSettings();

  isUsCustomer$ = this.store
    .select(state => state.auth)
    .pipe(
      map(auth => this.customerUtilService.isUsCustomer(auth.pk))
    );

  // If US customer and user does not have Recipe Profit Calculator entitlement role, then don't show Recipe tab
  static shouldOmitRecipe(
    accessRecipeCalculator: boolean,
    appKey: string
  ): boolean {
    return [
      !accessRecipeCalculator,
      appKey == applications.recipe,
    ].every(isTrue => isTrue)
  }


  static shouldAccessRecipeCalculator(
    entitlements: Entitlement[],
    customerPK: CustomerPK,
  ): boolean {
    return [
      entitlements
        .filter(ent => ent.customerPK.customerId == customerPK?.customerId)
        .some(ent => -1 < ent.roleName.indexOf(InventoryConstants.RECIPE_OKTA_ROLES)),
    ].every(isTrue => isTrue);
  }

  constructor(
    private configService: AppConfigService,
    @Inject(InjectionTokens.ACTIVE_APPLICATION) activeApp: string,
    public store: Store<AppState>,
    public customerUtilService: CustomerUtilService,
  ) {
    this.gfsApplications = [{
      label: 'APPLICATIONS.ORDERING',
      key: 'ordering',
      selected: false,
      url: this.settings.ORDERS_PATH,
      roles: [InventoryConstants.ORDERING_OKTA_ROLES]
    }, {
      label: 'APPLICATIONS.RECIPE',
      key: applications.recipe,
      selected: false,
      url: this.settings.RECIPE_PATH,
      roles: [InventoryConstants.RECIPE_OKTA_ROLES]
    },
    {
      label: 'APPLICATIONS.INVENTORY',
      key: applications.inventory,
      selected: false,
      url: this.settings.INVENTORY_PATH,
      roles: [InventoryConstants.INVENTORY_OKTA_ROLES]
    }].map(application => ({
      ...application,
      selected: activeApp === application.key
    }));
  }

  getApplications$(
    entitlements: Entitlement[],
    customerPK: CustomerPK,
  ): Observable<GFSRoute[]> {
    return forkJoin([
      of(this.gfsApplications),
      of(entitlements),
      of(customerPK),
    ]).pipe(
      map(([gfsApplications, entitlements, customerPK]) =>
        this.getApplications(gfsApplications, entitlements, customerPK))
    )
  }

  getApplications(
    apps: GFSRoute[],
    entitlements: Entitlement[],
    customerPK: CustomerPK,
  ): GFSRoute[] {

    return apps
      .filter(app => {


        return entitlements.find(ent =>
          app.roles.some(role =>
            -1 < ent.roleName.indexOf(role)));
      })
      .map(app => ({ ...app }))
      .map(app => {

        app.redirect = new RedirectUri(app.url);
        return app;
      });
  }
}
