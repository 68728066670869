import { Inject, Injectable } from '@angular/core';
import { GIRChatmodel, GIRdata } from './models/GIR-chat.model';
import { DOCUMENT } from '@angular/common';
import { CustomerBrand, EnvironmentSpecificService } from '../environment-specific/environment-specific.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { InventoryConstants, RecipeConstants } from '@gfs/constants';
import { WINDOW } from '../window.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from '@gfs/store/recipe/reducers';

interface GIRChatui {
  init(options?: object);
  updateState(options?: object);
  executeAction(options: object);
}

declare global {
  interface Window {
    __gordonNowChatUi: GIRChatui;
  }
}

@Injectable({
  providedIn: 'root'
})
export class GIRChatService {
  private readonly attempts = 10;
  private readonly initialDelay = 100; // ms
  private readonly maxDelay = 1500; // ms

  private hasCalledInit: boolean = false
  private chatModel: GIRChatmodel;
  customerPk$ = this.store.select(state => state.auth.pk);
  isCustomerSelection: boolean = true;

  constructor( 
    private router: Router,
    private environmentSpecificService: EnvironmentSpecificService,
    @Inject(WINDOW) private _window: Window,
    @Inject(DOCUMENT) private _document: Document,
    public translateService : TranslateService,
    private store: Store<AppState>,

) {
    if (this.environmentSpecificService.isGIRChatEnabled()) {
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          if(event.urlAfterRedirects.indexOf('implicit/callback')> -1){
            return
            }
            const isMcu = InventoryConstants.CUSTOMER_UNIT_SELECTION_PATH === event.urlAfterRedirects;
            this.isCustomerSelection = InventoryConstants.CUSTOMER_UNIT_SELECTION_PATH === event.urlAfterRedirects;
            this.updateGIR({
              hidden: isMcu,
            });
          
        });
    }
    
  }
  updateState(girData: GIRdata): void {
    if (girData) {
      const chatModel: GIRChatmodel = {
        chatChannel: this.environmentSpecificService.getGIRChatChannel(
          girData.brand
        ),
        customerUnitKey: girData.customerUnitKey,
        hidden: this.isCustomerSelection,
        language: {
          override: girData.language,
        },
        logRocket: {
          appId: this.environmentSpecificService.getLogRocketAppId(),
          isEnabled: true,
          parentIframeDomain: `${this._window.location.protocol}//${this._window.location.hostname}`,
        },
      };
      this.updateGIR(chatModel);
    }
  }

  initializeChatbot(){

    let chatbotLanguageCode = '';
    if (this.translateService.currentLang === RecipeConstants.LANGUAGES.EN_CA) {
      chatbotLanguageCode = 'en-CA';
    } else if (this.translateService.currentLang === RecipeConstants.LANGUAGES.FR_CA) {
      chatbotLanguageCode = 'fr-CA';
    }
    this.customerPk$.pipe(
    ).subscribe((pk)=>{
      if(pk){
        const GIRChatData = {
          brand:pk.entityType.split('-')[0] === 'ca' ? CustomerBrand.GFS_CA : CustomerBrand.GFS_US,
          customerUnitKey: {
            salesOrganization: pk?.salesOrg,
            distributionChannel: pk?.channel,
            division: pk?.division,
            customerNumber: pk?.customerId,
          },
          language:chatbotLanguageCode
        }
        this.updateState(GIRChatData)
      }
    })
  }


  updateGIR(partialChatModel: Partial<GIRChatmodel>): void {
    if (this.environmentSpecificService.isGIRChatEnabled()) {
      this.chatModel = { ...this.chatModel, ...partialChatModel };
      this.retry(this.attempts, this.initialDelay, () => {
        if (this.hasCalledInit) {
          this._window.__gordonNowChatUi.updateState(this.chatModel);
        } else if(!this.isCustomerSelection){
          this.hasCalledInit = true
          this._window.__gordonNowChatUi.init(
            this.chatModel,
          );
        }
      });
    }
  }
  private retry(attempts: number, delay: number, fn: () => void): void {
    try {
      fn();
    } catch (error) {
      if (attempts > 1) {
        const exponentialBackoff = Math.min(delay * 2, this.maxDelay);
        setTimeout(
          () => this.retry(attempts - 1, exponentialBackoff, fn),
          exponentialBackoff,
        );
      }
    }
  }


}
