import { Inject, Injectable } from '@angular/core';
import { catchError, tap, filter, withLatestFrom, concatMap, map, switchMap, first, timeout } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { Router } from '@angular/router';
import { applications, Constants, InventoryConstants } from '@gfs/constants';
import { APPLICATION_USER_ROLE, ApplicationUserRole, Entitlement, RECIPE_PROFIT_CALCULATOR_CONFIG, RecipeProfitCalculatorConfig } from '@gfs/shared-models';
import { DataLayerService } from '@gfs/shared-services/services/google-tag-manager/data-layer.service';
import { UserService } from '@gfs/shared-services/services/user/user.service';
import { WINDOW } from '@gfs/shared-services/services/window.service';
import { AuthUtil } from '@gfs/shared-services/util/auth/auth-util';
import { ClearSupplierData } from '@gfs/store/feature/add-items';
import { GetCustomerDataAttempt } from '@gfs/store/feature/customer-unit-selection/actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { union } from 'lodash-es';
import { NgxPermissionsService } from 'ngx-permissions';
import {
  ApplyUserCustomDimensions,
  AuthActionTypes as ActionTypes,
  AuthActionUnion,
  LogInError,
  LogInSuccess,
  LogOutError,
  LogOutSuccess,
  SetCustomerPK,
  SetUserPermissions,
  RedirectToApplication,
  GetUserAttributes,
  SetUserAttributes,
  NavigateToEmployeeCustomerSearch,
  SetCustomerLocations
} from '../../common/actions/auth.actions';
import { GetCartDataAttempt } from '../../features/cart';
import { InjectionTokens } from '../../injection-tokens';
import { SetLanguage } from '../actions/layout.actions';
import { IAuthStateFacade } from '../store/auth.state';
import { Authorizations } from '../store';
import { AuthenticationService, defaultAuthState } from '@gfs/shared-services/auth/authentication-state.service';
import { CustomerSearchService } from '@gfs/shared-services/services/customer-search.service';


@Injectable()
export class AuthEffects {

  constructor(
    private actions$: Actions<AuthActionUnion>,
    private userService: UserService,
    private router: Router,
    @Inject(WINDOW) private window: Window,
    private dataLayerService: DataLayerService,
    private store: Store<IAuthStateFacade>,
    private permissionsService: NgxPermissionsService,
    private authUtil: AuthUtil,
    @Inject(InjectionTokens.ACTIVE_APPLICATION) private activeApplication: 'recipe' | 'inventory',
    @Inject(RECIPE_PROFIT_CALCULATOR_CONFIG) public recipeProfitCalculatorConfig: RecipeProfitCalculatorConfig,
    public authenticationService: AuthenticationService,
    @Inject(APPLICATION_USER_ROLE) public userRole: ApplicationUserRole,
    public customerSearchService: CustomerSearchService,
  ) { }

  authStateTimeout: number = 3000;

  latestCustomerPk$ = this.store
    .select(state => state.auth.pk)
    .pipe(
      filter(pk => !!pk),
      first(),
    );


  logInAttempt$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.LogInAttempt),
    concatMap(action => {
      return forkJoin([
        of(action),
        of(this.userRole),
        this.authenticationService.authenticationState$()
          .pipe(
            filter(authState => {
              return authState.isAuthenticated;
            }),
            first(),
            timeout({
              each: this.authStateTimeout,
              with: () => {
                return of(defaultAuthState);
              }
            }),
            first()
          )
      ]).pipe(
        tap(([, , authState]) => {
          if (!authState.isAuthenticated) {
            throw new Error('User not authenticated');
          }
        }),
        concatMap(([action, userRole, authState]) => {
          const actions = new Array<Action>();

          // First action to dispatch must be LogInSuccess.
          // This ensures the access token will be added to app state by the LogInSuccess reducer
          //    before AuthorizationInterceptor queries app state for the token.
          // Without that token, requests made to resource servers will return 401
          //    and the login workflow will fail.
          const logInSuccessAction = new LogInSuccess({
            user: {
              claims: authState.claims,
              idToken: authState.idToken,
              accessToken: authState.accessToken,
              userRole: userRole,
            },
          });
          actions.push(logInSuccessAction);

          if (userRole == ApplicationUserRole.Customer) {
            const storedCustomerId = action.payload?.storedCustomerId;
            actions.push(new GetUserAttributes({ storedCustomerId }));
          }

          if (userRole == ApplicationUserRole.Employee) {
            actions.push(new NavigateToEmployeeCustomerSearch());
          }

          return actions;
        }),
        catchError(err => {
          return of(new LogInError(err));
        })
      );
    })));


  logInSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.LogInSuccess),
    concatMap(action => this.latestCustomerPk$
      .pipe(
        map(pk => ({ action, pk }))
      )),
    concatMap(({ action, pk }) => {
      const dispatches = new Array<Action>();
      dispatches.push(new ApplyUserCustomDimensions(action.payload.user));

      const setCustomerPk = new SetCustomerPK({
        customerPK: pk,
        shouldReroute: false,
        userRole: this.userRole,
      });
      dispatches.push(setCustomerPk);

      const preferredLangage = action.payload.user.claims?.preferredLanguage;
      const languageId = this.getLanguageId(preferredLangage);
      if (languageId) {
        dispatches.push(new SetLanguage(languageId));
      }

      return dispatches;
    })
  ));

  getUserAttributes$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetUserAttributes),
    concatMap(action => {
      return forkJoin([
        of(action),
        this.userService.getUserRolePermissions(),
        this.userService.getUserEntitlements(),
      ]).pipe(
        map(([action, permissions, entitlements]) => {
          return new SetUserAttributes({
            storedCustomerId: action.payload.storedCustomerId,
            permissions,
            entitlements,
            activeApplication: this.activeApplication
          });
        }),
        catchError(err => {
          return of(new LogInError(err));
        })
      )
    })
  ));


  applyCustomDimensions$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.ApplyUserCustomDimensions),
    withLatestFrom(this.store.pipe(filter(state => !!state.auth.pk))),
    tap(([action, state]) => {
      this.dataLayerService.push({
        [InventoryConstants.CUSTOMER_UNIT_CD_INDEX]: state.auth.pk.customerId,
        [InventoryConstants.USER_ID_CD_INDEX]: action.user.claims.guid
      });
    })
  ), { dispatch: false });


  logOut$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.LogOutAttempt),
    concatMap(() => {
      return this.authenticationService.logout$()
        .pipe(
          map(() => new LogOutSuccess()),
          catchError(err => {
            return of(new LogOutError(err));
          })
        )
    }),
  ));


  authErrorLogger$ = createEffect(() => this.actions$.pipe(
    ofType(
      ActionTypes.LogInError,
      ActionTypes.LogOutError
    ),
    tap(() => {
      this.router.navigateByUrl('/error');
    })
  ), { dispatch: false });


  selectLocation$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.SelectLocation),
    concatMap(({ payload: { customerPK, customerName } }) => {
      return [
        new ClearSupplierData(),
        new SetCustomerPK({
          customerPK,
          customerName,
          shouldReroute: true,
          userRole: this.userRole,
        }),
        new GetCustomerDataAttempt(customerPK),
        new GetCartDataAttempt({
          customerPK,
          includePrice: false,
        }),
      ];
    })
  ));


  setCustomerPK$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.SetCustomerPK),
    filter(action => !!action.payload.customerPK),
    tap(action => {
      const localCustomer = {
        customerId: action.payload.customerPK.customerId,
        entityType: action.payload.customerPK.entityType
      };
      this.window.localStorage.setItem('customer', JSON.stringify(localCustomer));
      this.dataLayerService.push({ [InventoryConstants.CUSTOMER_UNIT_CD_INDEX]: action.payload.customerPK.customerId });
    }),
    concatMap((action) => {
      const dispatch = new Array<Action>();
      dispatch.push(new GetCartDataAttempt({
        customerPK: action.payload.customerPK,
        includePrice: true
      }));
      if (action.payload.shouldReroute) {
        dispatch.push(new RedirectToApplication());
      }
      dispatch.push(new SetUserPermissions(action.payload.customerPK));
      return dispatch;
    })
  ));


  setUserPermissions$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.SetUserPermissions),
    withLatestFrom(this.store.pipe(filter(state => !!state.auth.rolePermissions && !!state.auth.entitlements))),
    tap(([action, state]) => {
      const usersInventoryEntitlements: Entitlement[] = state.auth.entitlements.filter((ent: Entitlement) => {

        if (ent.roleName.toLocaleLowerCase().includes(InventoryConstants.APPLICATION_ROLESET_MAPPING[this.activeApplication])) {
          return ent.customerPK.customerId === action.payload.customerId;
        }
        return false;
      });

      const selectedEntitlement = this.authUtil.processEntitlements(usersInventoryEntitlements);

      const perms = state.auth.rolePermissions.reduce((prev, current) => {
        const entitlementRole = selectedEntitlement.roleName || '';
        if (entitlementRole.includes(current.role)) {
          prev = union(prev, current.permissions);
        }
        return prev;
      }, []);

      // Cleaning permissions for previously selected customer unit
      this.permissionsService.flushPermissions();
      // Setting permissions for currently selected customer unit
      this.permissionsService.loadPermissions(perms);

    })
  ), { dispatch: false });


  idPAuthorize$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.IdPAuthorize),
    switchMap(() => {
      // Let the identity provider know you are active
      return this.authenticationService.reauthorizeWithoutPrompt$();
    })
  ), { dispatch: false });


  redirectToApplication$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.RedirectToApplication),
    withLatestFrom(this.store.select(state => state.auth)),
    tap(([, { pk, authorizations }]) => {
      this.handleRerouting(
        this.activeApplication,
        pk.entityType,
        this.router.url,
        authorizations);
    })
  ), { dispatch: false });


  navigateToEmployeeCustomerSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.NavigateToEmployeeCustomerSearch),
      tap(() => {
        const path = Constants.RecipeFeaturePaths
          .RECIPE_PROFIT_CALCULATOR[ApplicationUserRole.Employee]
          .RECIPE_PROFIT_CALCULATOR_CUSTOMER_SELECTION_PATH;
        this.router.navigateByUrl(path);
      })
    ), { dispatch: false });


  getCustomerLocations$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetCustomerLocations),
    concatMap(action => {
      return forkJoin([
        this.customerSearchService.searchCustomerLocations(action.payload.customerSearchRequest)
      ])
        .pipe(
          map(([customerSearchResults]) => {
            return new SetCustomerLocations({ customerSearchResults });
          })
        );
    })
  ));


  handleRerouting(
    activeApplication: string,
    entityType: string,
    url: string,
    authorizations: Authorizations
  ): void {
    if (activeApplication === applications.inventory) {
      if (InventoryConstants.CUSTOMER_ENTITIES.includes(entityType) && url !== '/inventory') {
        this.router.navigateByUrl('/inventory');
      } else if (InventoryConstants.GROUP_ENTITIES.includes(entityType) && url !== Constants.SharedFeaturePaths.MANAGE_NONGFS_ITEMS_PATHNAME) {
        this.router.navigateByUrl(Constants.SharedFeaturePaths.MANAGE_NONGFS_ITEMS_PATHNAME);
      }
    }

    if (activeApplication === applications.recipe) {
      const isUserRedirected = this.handleRedirectToRecipeProfitCalculator(authorizations);
      if (isUserRedirected) {
        return;
      }

      const groupEntities = InventoryConstants.GROUP_ENTITIES.includes(entityType);
      if (groupEntities && url !== Constants.SharedFeaturePaths.MANAGE_NONGFS_ITEMS_PATHNAME) {
        this.router.navigateByUrl(Constants.SharedFeaturePaths.MANAGE_NONGFS_ITEMS_PATHNAME);
      }
      else {
        this.router.navigateByUrl('/category');
      }
    }
  }


  handleRedirectToRecipeProfitCalculator(
    authorizations: Authorizations,
  ): boolean {
    const shouldRedirect = this.shouldRedirectToRecipeProfitCalculator(
      this.recipeProfitCalculatorConfig.isFeatureEnabled(),
      authorizations.RecipeProfitCalculator);

    if (shouldRedirect) {
      this.router.navigateByUrl(this.recipeProfitCalculatorConfig.RECIPE_PROFIT_CALCULATOR_PATH);
      return true;
    }

    return false;
  }

  shouldRedirectToRecipeProfitCalculator(
    recipeProfitCalculatorFeatureFlag: boolean,
    recipeProfitCalculatorAuthorization: boolean
  ): boolean {
    return [
      recipeProfitCalculatorFeatureFlag,
      recipeProfitCalculatorAuthorization
    ].every(isTrue => isTrue);
  }


  getLanguageId(preferredLanguage: string): string {
    const languageId = this.window.localStorage.getItem('languageId');
    if (languageId) {
      return languageId;
    }

    const languageIsValid = Object
      .values(InventoryConstants.LANGUAGES)
      .includes(preferredLanguage);
    if (languageIsValid) {
      return preferredLanguage;
    }

    const languageHasNoCountry = ['en', 'fr']
      .some(lang => lang == preferredLanguage);
    if (languageHasNoCountry) {
      return preferredLanguage + '_CA';
    }

    return InventoryConstants.LANGUAGES.DEFAULT;
  }
}
