<mat-accordion
  *ngIf="!!worksheetModel"
  id="storage-area-item-accordian"
  multi="true"
>
  <ng-container *ngIf="productFilter">
    <ng-container *ngIf="worksheetModel.storageAreaOrder as storageAreaOrder">
      <ng-container *ngFor="let currentStorageAreaId of storageAreaOrder">
        <ng-container *ngIf="worksheetModel.storageAreas[currentStorageAreaId] as storageArea">
          <ng-container *ngIf="productFilter[currentStorageAreaId] as storageAreaItemsOrder">
            <ng-container *ngIf="storageAreaExpansionState">

              <mat-expansion-panel
                class="storage-area-item-panel"
                [attr.id]="'storage-area-item-panel-' + storageArea.storageAreaId"
                [disabled]="(isMobile$ | async)"
                [(expanded)]="storageAreaExpansionState[currentStorageAreaId]"
              >

                <mat-expansion-panel-header
                  class="storage-area-item-panel-header item-background"
                  [collapsedHeight]="'40px'"
                  [expandedHeight]="'40px'"
                >

                  <mat-panel-title class="storage-area-title">
                    <div class="storage-area-name">
                      <gfs-localized-text-value [value]="storageArea.name" />
                      ({{storageAreaItemsOrder.length}})
                    </div>
                    <div
                      *ngxPermissionsOnly="pricingPermissions"
                      class="storage-area-total"
                    >
                      <div class="storage-area-total-inner"
                      *ngIf="canShowTotals()"
                      >
                        <gfs-localized-currency-value
                          *ngIf="getValuation(currentStorageAreaId) as valuation"
                          [class.blur-value]="isValueBlurred(valuation.status)"
                          [value]="valuation.value"
                          [placeholderText]="'—'"
                        />
                      </div>
                    </div>
                  </mat-panel-title>
                  <input
                    type="hidden"
                    #expansionPanelLocator
                    value="{{currentStorageAreaId}}"
                  >
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>

                  <div
                    *ngIf="!!storageArea"
                    cdkDropList
                    (cdkDropListDropped)="handleWorksheetItemCDKListDropEvent($event, currentStorageAreaId, true)"
                    [cdkDropListDisabled]="(hasFilterText$ | async) || ((isMobile$ | async))"
                  >
                    <div
                      *ngIf="!(isMobile$ | async)"
                      class="storage-area-card storage-area-card-dark"
                    >
                      <mat-grid-list
                        class="row-header-content"
                        cols="12"
                        rowHeight="32px"
                        gutterSize="0px"
                      >
                        <mat-grid-tile
                          class="mat-grid-tile-align-left"
                          [colspan]="(useGroupOperations$ | async) ? 5 : 6"
                        >{{ 'STORAGE_AREA.STORAGE_AREA_CARD.ITEM' | translate | uppercase }}
                        </mat-grid-tile>
                        <mat-grid-tile
                          class="mat-grid-tile-align-left"
                          colspan="3"
                        >{{ 'STORAGE_AREA.STORAGE_AREA_CARD.QUANTITY_ON_HAND' | translate | uppercase }}
                        </mat-grid-tile>
                        <mat-grid-tile
                          *ngxPermissionsOnly="pricingPermissions"
                          class="item-value-label-tile"
                          colspan="2"
                        >
                          {{ 'STORAGE_AREA.STORAGE_AREA_CARD.VALUE' | translate | uppercase }}
                        </mat-grid-tile>
                        <ng-container
                            *ngIf="(useGroupOperations$ | async)">
                          <mat-grid-tile
                          colspan="2"
                          class="item-value-label-tile select-all"
                          >
                          {{ 'STORAGE_AREA.STORAGE_AREA_CARD.SELECT_ALL' | translate | uppercase }}
                          <mat-checkbox
                            [checked]="storageAreasSelectedAllList.includes(currentStorageAreaId)"
                            (change)="onSelectAllClicked($event,currentStorageAreaId)"
                            matTooltip="{{ 'STORAGE_AREA.STORAGE_AREA_CARD.SELECT_ALL_TOOLTIP' | translate : getTranslatedStorageAreaName(storageArea) }}"
                            matTooltipPosition="before"
                            matTooltipClass="select-all-custom-tooltip"
                            >
                          </mat-checkbox>
                          </mat-grid-tile>
                        </ng-container>
                      </mat-grid-list>
                    </div>


                    <div *ngFor="let row of data">
                      <div
                        *ngIf="rowTemplateRef"
                        [ngTemplateOutlet]="rowTemplateRef"
                        [ngTemplateOutletContext]="{$implicit:row}"
                      >

                      </div>
                    </div>

                    <gfs-infinite-scroll-list
                      [getPage]="getPage.bind(this)"
                      [options]="uiConfig.scrollerOptions"
                      [scrollerHeight]="storageAreaItemsOrder.length <= 5 ? 'auto' : ''"
                      [enable]="uiConfig.infiniteScrollEnable"
                      [query]="{
                      storageAreaId: currentStorageAreaId
                    }"
                    >

                      <div
                        cdkDragBoundary=".storage-area-item-panel"
                        cdkDrag
                        [cdkDragDisabled]="(isMobile$ | async) || (useGroupOperations$ | async)"
                        *ngFor="let worksheetItemId of storageAreaItemsOrder | slice: getCurrentPageStartByStorageAreaId(currentStorageAreaId)
                    : getCurrentPageEndByStorageAreaId(currentStorageAreaId), index as i;"
                        class="storage-area-card"
                      >
                        <ng-container *ngIf="worksheetModel.worksheetItems[worksheetItemId] as item">

                          <ng-container *ngIf="worksheetModel.inventoryItems[item.itemId] as product">

                            <mat-grid-list
                              [id]="'inventory-item-' + item.id"
                              [cols]="(isMobile$ | async) ? 3 : 12"
                              [rowHeight]="(isMobile$ | async) ? '99px' : '94px'"
                              gutterSize="0px"
                            >
                              <mat-grid-tile
                                [colspan]="(isMobile$ | async) ? (hasItemTripleDot ? 2 : 3) : ((useGroupOperations$ | async) ? 5 : 6)"
                                class="mat-grid-tile-align-left"
                              >
                                <div
                                  class="drag-handle"
                                  cdkDragHandle
                                  *ngIf="!(useGroupOperations$ | async)"
                                >
                                  <img
                                    class="img-drag-handle"
                                    src="assets/images/draghandle.svg"
                                    alt="Drag & Drop Button"
                                    i18n-alt="@@DragAndDropAlt"
                                  />
                                </div>
                                <div
                                  class="item-ranking-wrapper"
                                  *ngIf="getWorksheetItemIndex(worksheetItemId, currentStorageAreaId) as indexResult "
                                >
                                  <input
                                    #itemRankInput
                                    type="number"
                                    class="gfs-numeric-input"
                                    [value]="indexResult.value + 1"
                                    (click)="$event.target.select()"
                                    [disabled]="(useGroupOperations$ | async)"
                                    min="1"
                                    (blur)="handleWorksheetItemIndexChange($event, currentStorageAreaId, indexResult.value, itemRankInput)"
                                    attr.aria-label="{{ 'STORAGE_AREA.STORAGE_AREA_CARD.ITEM_SEQUENCE' | translate }}"
                                  />
                                </div>
                                <div class="item-image-wrapper">
                                  <gfs-localized-image-value
                                    [id]="'product-image-' + product.id"
                                    [style]="'max-height: 32px; max-width:32px;align-self: center;'"
                                    [class]="'storage-item-thumbnail'"
                                    [value]="product.referenceImage"
                                    [requestHeight]="35"
                                    [requestWidth]="35"
                                    [alt]="product.descriptionLine1"
                                  />
                                </div>
                                <div
                                  class="item-description-container"
                                  *ngIf="!!product else missingItem"
                                >
                                  <div *ngIf="isGFSItem(item.itemId)"
                                    [id]="'product-title-' + product.id"
                                    class="item-title"
                                  >
                                    <a  href="{{ customerOrderingUrl( product.itemId) }}" target='_blank' class="item-title">

                                    <gfs-localized-text-value
                                      [value]="product.descriptionLine1"
                                      [maxLength]="50"
                                    />
                                    </a>
                                  </div>
                                  <div *ngIf="!isGFSItem(item.itemId)"
                                    [id]="'product-title-' + product.id"
                                    class="item-title"
                                  >
                                    <gfs-localized-text-value
                                      [value]="product.descriptionLine1"
                                      [maxLength]="50"
                                    />

                                  </div>
                                  <div
                                    [id]="'product-details-' + product.id"
                                    class="item-details"
                                  >
                                    <gfs-localized-text-value [value]="product.descriptionLine2" />
                                  </div>
                                </div>
                                <ng-template #missingItem>
                                  <div class="item-description-container">
                                    <div
                                      [id]="'product-title-' + product.id"
                                      class="item-title"
                                    >
                                      {{ 'STORAGE_AREA.STORAGE_AREA_CARD.MISSING_ITEM.DESCRIPTION' | translate: {
                                      'itemId':
                                      item.itemId } }}
                                    </div>
                                  </div>
                                </ng-template>
                              </mat-grid-tile>
                              <ng-container *ngxPermissionsOnly="permissionRoles.itemTripleDot">
                                <mat-grid-tile
                              [colspan]="1"
                              *ngIf="(isMobile$ | async) && (useGroupOperations$ | async)">
                                <mat-checkbox class="group-delete-option"
                                  [value]="{Data:{item : item , product : product}}"
                                  [checked]="isWorksheetItemChecked({Data:{item : item , product : product}})"
                                  (change)="checkboxSelectedForWorksheetItem($event)"
                                  >
                                </mat-checkbox>
                              </mat-grid-tile>
                                <mat-grid-tile
                                  *ngIf="(isMobile$ | async) && !(useGroupOperations$ | async)"
                                  [colspan]="1"
                                >
                                <div class="add-to-cart-icon"
                                (keydown)="addToCart(item)"
                                 (click)="addToCart(item)"
                                 [ngClass]="{'disabled' : (cartService.disbaleSubject | async).disable
                                 && (cartService.disbaleSubject | async).itemId === item.itemId.split('_')[1]}"
                                 *ngIf="item.itemId.split('_')[0] === 'GFS'">
                                 <mat-icon
                                 [matTooltip]="'STORAGE_AREA.ADD_TO_CART' | translate" matTooltipClass="recipe-icon-tooltip"
                                 >add_shopping_cart
                               </mat-icon>
                                 </div>
                                  <button
                                    mat-icon-button
                                    [matMenuTriggerFor]="itemMenu"
                                  >
                                    <mat-icon svgIcon="triple-dot-menu-sideways"></mat-icon>
                                  </button>
                                </mat-grid-tile>
                              </ng-container>
                              <mat-grid-tile [colspan]="(isMobile$ | async) ? 2 : 3">
                                <div class="item-count-container">
                                  <app-inventory-count-list
                                    [localizedUnits]="worksheetModel.units"
                                    [disableUnitChange]="uiConfig.disableUpdateWorksheetItemUnit"
                                    [worksheetItem]="item"
                                    (countEntryCompleted)="moveToNextItem($event)"
                                    (productCountChange)="this.productCountChange.emit($event)"
                                  />
                                </div>
                              </mat-grid-tile>
                              <mat-grid-tile
                                [colspan]="(isMobile$ | async) ? 1 : 2"
                                class="item-value-tile"
                              >
                                <div
                                  *ngxPermissionsOnly="pricingPermissions"
                                  class="item-value-container"
                                >
                                  <div
                                    *ngFor="let countEntry of item.itemCounts;let i = index;"
                                    class="item-value-{{i}}"
                                  >
                                    <ng-container [ngSwitch]="canComputeValue(worksheetItemId, countEntry.unit)">
                                      <ng-container *ngSwitchCase="true">
                                        <gfs-localized-currency-value
                                          *ngIf="getValuation(worksheetItemId, countEntry.ordinal) as valuation"
                                          [value]="valuation.value"
                                          [placeholderText]="'—'"
                                        />
                                      </ng-container>

                                      <ng-container *ngSwitchDefault>
                                        <gfs-warning-icon
                                          *ngIf="isGFSItem(item.itemId)"
                                          [i18nTooltip]="'RECIPE.CREATE.MISSING_INFO_GFS'"
                                          [isPlaceholder]="false"
                                        >
                                        </gfs-warning-icon>
                                        <gfs-warning-icon
                                          *ngIf="!isGFSItem(item.itemId)"
                                          [i18nTooltip]="'RECIPE.CREATE.MISSING_INFO'"
                                          [isPlaceholder]="false"
                                        >
                                        </gfs-warning-icon>
                                      </ng-container>
                                    </ng-container>

                                  </div>
                                </div>
                              </mat-grid-tile>
                              <ng-container *ngxPermissionsOnly="permissionRoles.itemTripleDot">
                                <mat-grid-tile *ngIf="!(isMobile$ | async) && !(useGroupOperations$ | async)">
                                  <div class="icon-container">
                                    <div class="add-to-cart-icon"
                                     (keydown)="addToCart(item)"
                                      (click)="addToCart(item)"
                                      [ngClass]="{'disabled' : (cartService.disbaleSubject | async).disable
                                      && (cartService.disbaleSubject | async).itemId === item.itemId.split('_')[1]}"
                                      *ngIf="item.itemId.split('_')[0] === 'GFS'">
                                      <mat-icon
                                      [matTooltip]="'STORAGE_AREA.ADD_TO_CART' | translate" matTooltipClass="recipe-icon-tooltip"
                                      >add_shopping_cart
                                    </mat-icon>
                                      </div>
                                      <button
                                        mat-icon-button
                                        [matMenuTriggerFor]="itemMenu"
                                        attr.aria-label="{{ 'STORAGE_AREA.STORAGE_AREA_CARD.TRIPLE_DOT_MENU_ARIA_LABEL' | translate }}"
                                      >
                                        <mat-icon svgIcon="triple-dot-menu"></mat-icon>
                                      </button>
                                  </div>
                                </mat-grid-tile>
                              </ng-container>
                              <ng-container
                              *ngIf="useGroupOperations$ | async">
                              <mat-grid-tile
                              [colspan]="2"
                                *ngIf="!(isMobile$ | async)">
                                <mat-checkbox class="group-delete-option"
                                  [value]="{Data:{item : item , product : product}}"
                                  [checked]="(isWorksheetItemChecked({Data:{item : item , product : product}}) )"
                                  (change)="checkboxSelectedForWorksheetItem($event)"
                                  >
                                </mat-checkbox>
                              </mat-grid-tile>
                            </ng-container>
                            </mat-grid-list>
                            <mat-menu
                              #itemMenu
                              class="worksheet-tripledot-menu"
                            >
                              <button
                                *ngIf="product"
                                [disabled]="!uiConfig.isOnline"
                                [routerLink]="[
                              { outlets: { 'modal': [ 'product', worksheetItemId,'configure' ] } }
                            ]"
                                routerLinkActive="active"
                                id="item-menu-edit"
                                mat-menu-item
                              >
                                {{
                                uiConfig.disableUpdateWorksheetItemUnit
                                ? ('STORAGE_AREA.STORAGE_AREA_CARD.TRIPLE_DOT_MENU.VIEW' | translate)
                                : ('STORAGE_AREA.STORAGE_AREA_CARD.TRIPLE_DOT_MENU.EDIT' | translate)
                                }}
                              </button>
                              <button
                                *ngIf="product"
                                (click)="duplicateClicked(item, product)"
                                [disabled]="!uiConfig.isOnline"
                                id="item-menu-duplicate"
                                mat-menu-item
                              >
                                {{ 'STORAGE_AREA.STORAGE_AREA_CARD.TRIPLE_DOT_MENU.DUPLICATE' | translate }}
                              </button>
                              <button
                                [disabled]="!uiConfig.isOnline"
                                [matMenuTriggerFor]="moveTo"
                                id="item-menu-move-to"
                                mat-menu-item
                              >
                                {{ 'STORAGE_AREA.STORAGE_AREA_CARD.TRIPLE_DOT_MENU.MOVE_TO' | translate }}
                              </button>

                              <button
                                (click)="deleteClicked(item , product)"
                                [disabled]="!uiConfig.isOnline"
                                id="item-menu-delete"
                                mat-menu-item
                              >
                                {{ 'STORAGE_AREA.STORAGE_AREA_CARD.TRIPLE_DOT_MENU.REMOVE' | translate }}
                              </button>
                            </mat-menu>
                            <mat-menu #moveTo="matMenu">
                              <ng-container *ngFor="let storageAreaTargetId of worksheetModel.storageAreaOrder">
                                <ng-container
                                  *ngIf="worksheetModel.storageAreas[storageAreaTargetId] as storageAreaTarget"
                                >
                                  <button
                                    *ngIf="storageAreaTargetId !== currentStorageAreaId"
                                    id="item-menu-move-to-sa-{{storageAreaTargetId}}"
                                    mat-menu-item
                                    (click)="moveToStorageAreaClicked(item ,product, storageAreaTargetId)"
                                  >
                                    <gfs-localized-text-value [value]="storageAreaTarget.name" />
                                  </button>
                                </ng-container>
                              </ng-container>
                            </mat-menu>
                          </ng-container>

















                        </ng-container>

                      </div>

                      <ng-template #rowSkeleton>
                        <div class="infinite-scroll-row-skeleton-loader align-center">
                          <mat-spinner
                            [diameter]="100"
                            [strokeWidth]="70"
                          ></mat-spinner>
                        </div>
                      </ng-template>

                      <ng-template
                        let-scroller
                        #eod
                      >
                        <div
                          class="infinite-scroll-row-custom-content align-center"
                          #eod
                        >
                          <button
                            type="button"
                            mat-button
                            disableRipple
                            matSuffix
                            (click)="scroller.goToTop()"
                          >
                            Back To Top
                          </button>
                        </div>
                      </ng-template>
                    </gfs-infinite-scroll-list>

                  </div>
                  <input
                    type="hidden"
                    #paginatorLocator
                    value="{{currentStorageAreaId}}"
                  >
                  <mat-paginator
                    #storageAreaPaginator
                    class="storage-area-paginator"
                    [class.hide-element-paginator]="uiConfig.infiniteScrollEnable"
                    [length]="storageAreaItemsOrder.length"
                    [pageSize]="uiConfig.pageSize"
                    [pageIndex]="getCurrentPageIndex(currentStorageAreaId)"
                    (page)="onPageClick($event, currentStorageAreaId)"
                    hidePageSize="true"
                    showFirstLastButtons="true"
                  >
                  </mat-paginator>

                </ng-template>

              </mat-expansion-panel>

            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

</mat-accordion>
<router-outlet name='modal'></router-outlet>
