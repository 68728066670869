import { Action } from '@ngrx/store';
import { User, CustomerPK, RolePermission, Entitlement, CustomerSearchRequest, CustomerSearchResult, ApplicationUserRole } from '@gfs/shared-models';
import { Authorization } from '../store';

export enum AuthActionTypes {
  LogInAttempt = '[Auth] Log In Attempt',
  LogInSuccess = '[Auth] Log In Success',
  LogInError = '[Auth] Log In Error',
  LogOutAttempt = '[Auth] Log Out Attempt',
  LogOutSuccess = '[Auth] Log Out Success',
  LogOutError = '[Auth] Log Out Error',
  SetCustomerPK = '[Auth] Sets the customer PK',
  ApplyUserCustomDimensions = '[Auth] Apply users custom dimensions',
  SetUserPermissions = '[Auth] Set User Permissions',
  IdPAuthorize = '[Auth] IdP Authorize',
  SelectLocation = '[Auth] Select Location',
  SelectLocationSuccess = '[Auth] Select Location Success',
  SelectLocationError = '[Auth] Select Location Error',
  SetAuthorization = '[Auth] Set Authorization',
  RedirectToApplication = '[Auth] Redirect to Application',
  GetUserAttributes = '[Auth] Get User Attributes',
  SetUserAttributes = '[Auth] Set User Attributes',
  GetCustomerLocations = '[Auth] Get Customer Locations',
  SetCustomerLocations = '[Auth] Set Customer Locations',
  NavigateToEmployeeCustomerSearch = '[Auth] Navigate to Customer Search for Employee Users',
  SetNamAccessTokenRefresh = '[Auth]Refresh access token for NAM'
}

export class LogInAttempt implements Action {
  readonly type = AuthActionTypes.LogInAttempt;
  constructor(public payload: {
    storedCustomerId: string
  }) { }
}

export class LogInSuccess implements Action {
  readonly type = AuthActionTypes.LogInSuccess;
  constructor(public payload: { user: User }) { }
}

export class LogInError implements Action {
  readonly type = AuthActionTypes.LogInError;
  constructor(public error: Error) { }
}

export class LogOutAttempt implements Action {
  readonly type = AuthActionTypes.LogOutAttempt;
}

export class LogOutSuccess implements Action {
  readonly type = AuthActionTypes.LogOutSuccess;
}

export class LogOutError implements Action {
  readonly type = AuthActionTypes.LogOutError;
  constructor(public error: Error) { }
}

export class SetCustomerPK implements Action {
  readonly type = AuthActionTypes.SetCustomerPK;
  constructor(public payload: {
    customerPK: CustomerPK,
    customerName?: string,
    shouldReroute: boolean,
    userRole?: ApplicationUserRole,
  }) { }
}

export class ApplyUserCustomDimensions implements Action {
  readonly type = AuthActionTypes.ApplyUserCustomDimensions;
  constructor(public user: User) { }
}

export class SetUserPermissions implements Action {
  readonly type = AuthActionTypes.SetUserPermissions;
  constructor(public payload: CustomerPK) { }
}

export class IdPAuthorize implements Action {
  readonly type = AuthActionTypes.IdPAuthorize;
}

export class SelectLocation implements Action {
  readonly type = AuthActionTypes.SelectLocation;
  constructor(public payload: { customerPK: CustomerPK, customerName: string }) { }
}

export class SelectLocationSuccess implements Action {
  readonly type = AuthActionTypes.SelectLocationSuccess;
}

export class SelectLocationError implements Action {
  readonly type = AuthActionTypes.SelectLocationError;
}

export class SetAuthorization implements Action {
  readonly type = AuthActionTypes.SetAuthorization;
  constructor(public payload: { authorization: Authorization, value: boolean }) { }
}

export class RedirectToApplication implements Action {
  readonly type = AuthActionTypes.RedirectToApplication;
}

export class GetUserAttributes implements Action {
  readonly type = AuthActionTypes.GetUserAttributes;
  constructor(public payload: { storedCustomerId: string }) { }
}

export class SetUserAttributes implements Action {
  readonly type = AuthActionTypes.SetUserAttributes;
  constructor(public payload: {
    storedCustomerId: string,
    permissions: RolePermission[],
    entitlements: Entitlement[],
    activeApplication: string
  }) { }
}

export class GetCustomerLocations implements Action {
  readonly type = AuthActionTypes.GetCustomerLocations;
  constructor(public payload: { customerSearchRequest: CustomerSearchRequest }) { }
}

export class SetCustomerLocations implements Action {
  readonly type = AuthActionTypes.SetCustomerLocations;
  constructor(public payload: { customerSearchResults: CustomerSearchResult[] }) { }
}

export class NavigateToEmployeeCustomerSearch implements Action {
  readonly type = AuthActionTypes.NavigateToEmployeeCustomerSearch;
}

export class SetNamAccessTokenRefresh implements Action {
  readonly type = AuthActionTypes.SetNamAccessTokenRefresh;
  constructor(public payload: string) { }
}

export type AuthActionUnion =
  LogInAttempt
  | LogInSuccess
  | LogInError
  | LogOutAttempt
  | LogOutSuccess
  | LogOutError
  | SetCustomerPK
  | ApplyUserCustomDimensions
  | SetUserPermissions
  | IdPAuthorize
  | SelectLocation
  | SetAuthorization
  | RedirectToApplication
  | GetUserAttributes
  | SetUserAttributes
  | GetCustomerLocations
  | SetCustomerLocations
  | NavigateToEmployeeCustomerSearch
  | SetNamAccessTokenRefresh
  ;
