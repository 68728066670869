import { Inject, Injectable } from '@angular/core';
import GIRProperties from 'GIR-chat.json';
import { WINDOW } from '../window.service';
import { InjectionTokens } from 'libs/shared-services/src/injection-tokens';
import { applications } from '@gfs/constants';

export enum HeaderLinkType {
  RECIPE = 'recipe',
  INVENTORY = 'inventory',
}
export enum CustomerBrand {
  GFS_CA = 'GFS_CA',
  GFS_US = 'GFS_US',
}

export interface EnvironmentConfig {
  adZerkUrl: string;
  chatChannelEnv: string;
  headerUrls: Record<HeaderLinkType, string>;
  googleMapsApiKey: string;
}
export enum Environment {
  local = 'local',
  dev = 'dev',
  tst = 'tst',
  qat = 'qat',
  sit = 'sit',
  prd = 'prd',
}

interface GIRenvironments {
  local: EnvironmentConfig;
  dev: EnvironmentConfig;
  tst: EnvironmentConfig;
  qat: EnvironmentConfig;
  sit: EnvironmentConfig;
  prd: EnvironmentConfig;
}

@Injectable({
  providedIn: 'root'
})
export class EnvironmentSpecificService {
  activeApplication: string;
  private inventoryStagingUrl = 'https://inventory-staging.gfs.com';
  private inventoryProdUrl = 'https://inventory.gfs.com';
  private recipeStagingUrl = 'https://recipe-staging.gfs.com';
  private recipeProdUrl = 'https://recipe.gfs.com';
  private googleMapsApiProdKey = 'AIzaSyC-rSV6NYMdbwKqNbJWkcZZGh0aEKrwnc8';
  private googleMapsApiStagingKey = 'AIzaSyB-BZC1csDjNnKpycnUCZexXqUPJ0SOrSQ';
  private adZerkUrl = 'https://digitalcontent.gfs.com/api/v2';
  private inventorylogRocketPrefix = 'fwixe9/gordon-inventory-';
  private recipeLogrocketPrefix ='fwixe9/gordon-recipe-';
  private lrKey = {
    dev : 'tpdco',
     tst : 'ekanb',
     sit : '6xtfw',
    prd : '2heen'
  }

  private environmentSpecific: GIRenvironments = {
    local: {
      adZerkUrl: this.adZerkUrl,
      chatChannelEnv: Environment.sit,
      headerUrls: {
        recipe: this.recipeStagingUrl,
        inventory: this.inventoryStagingUrl,
      },
      googleMapsApiKey: this.googleMapsApiStagingKey,
    },
    dev: {
      adZerkUrl: this.adZerkUrl,
      chatChannelEnv: Environment.sit,
      headerUrls: {
        recipe: this.recipeStagingUrl,
        inventory: this.inventoryStagingUrl,
      },
      googleMapsApiKey: this.googleMapsApiStagingKey,
    },
    tst: {
      adZerkUrl: this.adZerkUrl,
      chatChannelEnv: Environment.sit,
      headerUrls: {
        recipe: this.recipeStagingUrl,
        inventory: this.inventoryStagingUrl,
      },
      googleMapsApiKey: this.googleMapsApiStagingKey,
    },
    qat: {
      adZerkUrl: this.adZerkUrl,
      chatChannelEnv: Environment.sit,
      headerUrls: {
        recipe: this.recipeStagingUrl,
        inventory: this.inventoryStagingUrl,
      },
      googleMapsApiKey: this.googleMapsApiStagingKey,
    },
    sit: {
      adZerkUrl: this.adZerkUrl,
      chatChannelEnv: Environment.sit,
      headerUrls: {
        recipe: this.recipeStagingUrl,
        inventory: this.inventoryStagingUrl,
      },
      googleMapsApiKey: this.googleMapsApiStagingKey,
    },
    prd: {
      adZerkUrl: this.adZerkUrl,
      chatChannelEnv: Environment.prd,
      headerUrls: {
        recipe: this.recipeProdUrl,
        inventory: this.inventoryProdUrl,
      },
      googleMapsApiKey: this.googleMapsApiProdKey,
    },
  }

  constructor(
    @Inject(WINDOW) public _window: Window,
    @Inject(InjectionTokens.ACTIVE_APPLICATION) activeApp: 'recipe' | 'inventory',
    ) { this.activeApplication = activeApp }

  getLogRocketAppId(): string {
    const env = this.getEnvironment();
    let completeUrl :string
    if(this.activeApplication === applications.inventory){
      completeUrl = this.inventorylogRocketPrefix + env + '-' + this.lrKey[env]
    } else {
      completeUrl = this.recipeLogrocketPrefix + env
    }
    return completeUrl
  }

  isGIRChatEnabled(): boolean {
    return [Environment.dev , Environment.local , Environment.prd , Environment.qat ,
    Environment.sit , Environment.tst].includes(
      this.getEnvironment(),
    );
  }

  isProduction(): boolean {
    return this.getEnvironment() === Environment.prd;
  }


  getGIRChatChannel(customerBrand: CustomerBrand): string {
    const brand = customerBrand
      .substr(customerBrand.indexOf('_') + 1)
      .toLowerCase();
    const env = this.getEnvironment();
    return `cx-gordon-ordering-${brand}-${this.environmentSpecific[env].chatChannelEnv}`;
  }

  getGIRChatScriptAttributes(): Map<string, string> {
    const scriptAttributes = new Map<string, string>();
    scriptAttributes.set('id', 'gordon-now-chat-env');
    scriptAttributes.set('data-manualInit', 'true');
    scriptAttributes.set('crossorigin', 'anonymous');
    const environmentProperty = this.isProduction()
      ? GIRProperties.prd
      : GIRProperties.sit;
    scriptAttributes.set('src', environmentProperty.url);
    return scriptAttributes;
  }

   getEnvironment(): Environment | null {
    if (this._window.location.hostname.endsWith('inventory-dev.gfs.com') || this._window.location.hostname.endsWith('recipe-dev.gfs.com')) {
      return Environment.dev;
    } else if (this._window.location.hostname.endsWith('inventory-tst.gfs.com') || this._window.location.hostname.endsWith('recipe-tst.gfs.com')) {
      return Environment.tst;
    } else if (this._window.location.hostname.endsWith('inventory-qat.gfs.com') || this._window.location.hostname.endsWith('recipe-qat.gfs.com')) {
      return Environment.qat;
    } else if (this._window.location.hostname.endsWith('inventory-staging.gfs.com') || this._window.location.hostname.endsWith('recipe-staging.gfs.com')){
      return Environment.sit;
    } else if (this._window.location.hostname.endsWith('inventory.gfs.com') || this._window.location.hostname.endsWith('recipe.gfs.com')) {
      return Environment.prd;
    } else if (this._window.location.hostname.startsWith('localhost')) {
      return Environment.local;
    }
  }

}
